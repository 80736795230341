import Enum from "Includes/Enum.js";

/**
 * Customer discovery methods
 * 
 * @package RedSky
 * @subpackage Customers
 * @author Heron Web Ltd
 * @copyright Red Sky Shepherds Huts Limited
 */
class CustomerDiscoveryMethod extends Enum {

	static Unknown = 1;

	static InternetSearch = 2;

	static MagazineCotswoldLife = 3;

	static MagazineCountrysideNfu = 12;

	static MagazineCountryHomesInteriors = 13;

	static MagazineCountryLiving = 14;

	static MagazineHouseGarden = 15;

	static MagazineOkMagazine = 16;

	static MagazineWorldOfInteriors = 17;

	static NewspaperArticle = 4;

	static Recommendation = 5;

	static Brochure = 6;

	static Instagram = 7;

	static Facebook = 8;

	static Pinterest = 9;

	static BritishSaunaSociety = 18;

	static StayedInHut = 10;

	static Other = 11;


	static getLabel(name) {
		return ({
			InternetSearch: "Internet Search",
			MagazineCotswoldLife: "Magazine — Cotswold Life",
			MagazineCountrysideNfu: "Magazine — Countryside (NFU)",
			MagazineCountryHomesInteriors: "Magazine — Country Homes & Interiors",
			MagazineCountryLiving: "Magazine — Country Living",
			MagazineHouseGarden: "Magazine — House & Garden",
			MagazineOkMagazine: "Magazine — OK Magazine",
			MagazineWorldOfInteriors: "Magazine — The World of Interiors",
			NewspaperArticle: "Newspaper Article",
			BritishSaunaSociety: "British Sauna Society",
			StayedInHut: "Stayed in a Red Sky Hut"
		}[name] || name);
	}

}

export default CustomerDiscoveryMethod;
