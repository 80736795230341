import api from "api.js";
import scss from "./CorrespondenceEditorDialog.module.scss";
import useForm from "Hooks/useForm.js";
import CorrespondenceChannelPicker from "./CorrespondenceChannelPicker.js";
import CustomerContactPicker from "Customers/CustomerContactPicker.js";
import Dialog from "Components/Dialog.js";
import Flex from "Components/Flex.js";
import StaffMemberPicker from "StaffMembers/StaffMemberPicker.js";
import ToastStore from "Toasts/ToastStore.js";
import moment from "moment";
import {memo, useCallback, useState} from "react";
import {Button, Form} from "semantic-ui-react";

const CorrespondenceForm = memo(({
	correspondence,
	project,
	revision,
	open,
	onClose,
	onSaved
}) => {

	const form = useForm({
		Content: (correspondence?.Content || null),
		StaffMember: (correspondence?.StaffMember || null),
		CustomerContact: (correspondence?.CustomerContact || null),
		Channel: (correspondence?.Channel?.Name || null),
		Incoming: (correspondence?.Incoming || false),
		DateTime: (new moment(correspondence?.DateTime)).format("YYYY-MM-DDTHH:mm")
	});


	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleSetIncoming = useCallback(() => {
		form.setState({
			...form.state,
			Incoming: true
		});
	}, [form]);


	const handleSetOutgoing = useCallback(() => {
		form.setState({
			...form.state,
			Incoming: false
		});
	}, [form]);


	const handleSubmit = useCallback(async () => {

		if (!form.state.Channel) {
			ToastStore.error("Please select the Correspondence Type.");
			return;
		}

		setIsSubmitting(true);

		try {

			const data = {
				Content: form.state.Content,
				StaffMember: (form.state.StaffMember || null),
				CustomerContact: (form.state.CustomerContact || null),
				Channel: form.state.Channel,
				Incoming: form.state.Incoming,
				DateTime: (new moment(form.state.DateTime)).format("YYYY-MM-DD HH:mm:ss")
			};

			const result = await api({
				url: (
					!correspondence ?
						`/projects/build/revisions/${revision.Id}/correspondence` :
						`/projects/build/revisions/correspondence/${correspondence.Id}`
				),
				method: (!correspondence ? "POST" : "PUT"),
				data: {
					...data,
					StaffMember: (data.StaffMember?.Id || null),
					CustomerContact: (data.CustomerContact?.Id || null)
				}

			}).then(({data}) => data);

			/**
			 * `PUT` API doesn't currently give us the saved object
			 * back so we have to hydrate our own to pass to callers
			 */
			if (!result && correspondence) {
				data.Id = correspondence.Id;
			}

			onClose();
			onSaved((result || data));

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [
		correspondence,
		revision,
		onClose,
		onSaved,
		form.state
	]);


	return (
		<Dialog
			open={open}
			onClose={onClose}
			okButtonForm={form.id}
			okButtonType="submit"
			submitting={isSubmitting}
			title="Project Correspondence">
			<Form
				id={form.id}
				onSubmit={handleSubmit}>
				<Flex>
					<Button.Group
						className={scss.controls__outbound}
						disabled={isSubmitting}>
						<Button
							active={!form.state.Incoming}
							disabled={isSubmitting}
							content="Sent"
							icon="arrow up"
							onClick={handleSetOutgoing}
							type="button" />
						<Button
							active={form.state.Incoming}
							disabled={isSubmitting}
							content="Received"
							icon="arrow down"
							onClick={handleSetIncoming}
							type="button" />
					</Button.Group>
					<Flex
						className={scss.controls}
						columnar={true}
						wrap={true}>
						<Form.Input
							disabled={isSubmitting}
							label="Correspondence Date/Time"
							name="DateTime"
							max={(new moment()).format("YYYY-MM-DDTHH:mm")}
							onChange={form.updateStateFromSemanticInputChangeEvent}
							required={true}
							type="datetime-local"
							value={(form.state.DateTime || "")} />
						<Form.Field>
							<CorrespondenceChannelPicker
								disabled={isSubmitting}
								name="Channel"
								onChange={form.updateProp}
								required={true}
								value={form.state.Channel} />
						</Form.Field>
						{
							project.Customer &&
								<>
									<Form.Field
										disabled={isSubmitting}>
										<label
											children="Staff Member" />
										<StaffMemberPicker
											disabled={isSubmitting}
											label="Staff Member"
											name="StaffMember"
											onChange={form.updateProp}
											required={true}
											value={form.state.StaffMember} />
									</Form.Field>
									<Form.Field
										disabled={isSubmitting}>
										<label
											children="Customer Contact" />
										<CustomerContactPicker
											disabled={isSubmitting}
											customerId={project.Customer?.Id}
											name="CustomerContact"
											onChange={form.updateProp}
											required={true}
											value={form.state.CustomerContact} />
									</Form.Field>
								</>
						}
					</Flex>
					<Form.TextArea
						autoFocus={true}
						disabled={isSubmitting}
						label="Content"
						placeholder="Paste the content of the correspondence"
						maxLength={65535}
						name="Content"
						onChange={form.updateStateFromSemanticInputChangeEvent}
						required={true}
						rows={8}
						value={(form.state.Content || "")} />
				</Flex>
			</Form>
		</Dialog>
	);

});

export default CorrespondenceForm;
