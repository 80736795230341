import ButtonGrid from "Components/ButtonGrid.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import View from "Views/View.js";
import useAppMenuItems from "Hooks/useAppMenuItems.js";

const IndexView = () => {

	const menuItems = useAppMenuItems().filter(i => (i.uri !== "/"));

	return (
		<View>
			<h1>Red Sky Shepherd's Huts</h1>
			<ButtonGrid
				items={menuItems} />
			{
				!menuItems.length &&
					<SubduedStateLabel
						content="Welcome to Red Sky Shepherd's Huts." />
			}
		</View>
	);

};

export default IndexView;
