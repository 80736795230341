import AddressFormInputs from "Components/AddressFormInputs.js";
import CustomerContactPicker from "Customers/CustomerContactPicker.js";
import DetailsPanel from "Components/DetailsPanel.js";
import EnumChip from "Components/EnumChip.js";
import Flex from "Components/Flex.js";
import PaneHeader from "Components/PaneHeader.js";
import ProjectAssigneeTypePicker from "./ProjectAssigneeTypePicker.js";
import ProjectDeletionDialog from "./ProjectDeletionDialog.js";
import ProjectPhasePicker from "./ProjectPhasePicker.js";
import ProjectStatus from "./ProjectStatus.js";
import ProjectStatusPicker from "./ProjectStatusPicker.js";
import StaffMemberPicker from "StaffMembers/StaffMemberPicker.js";
import ToastStore from "Toasts/ToastStore.js";
import api from "api.js";
import useValue from "Hooks/useValue.js";
import {memo, useCallback, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button, Container, Form, Segment} from "semantic-ui-react";

const ProjectDetailsPanel = memo(({
	className,
	embedded,
	hideCustomerDetails,
	onDeleteProject,
	onUpdateProject,
	project
}) => {

	const navigate = useNavigate();

	const deletionDialogOpen = useValue(false);

	const [editState, setEditState] = useState({...project});
	const [hasEdits, setHasEdits] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);


	const handleInputPropChange = useCallback((value, name) => {

		setHasEdits(true);

		setEditState({
			...editState,
			[name]: value
		});

	}, [editState]);


	const handleInputChange = useCallback((e, {name, value}) => {
		handleInputPropChange(value, name);
	}, [handleInputPropChange]);


	const handleStatusInputChange = useCallback(Status => {

		setHasEdits(true);

		setEditState({
			...editState,
			Status,
			Phase: ProjectStatus.getDefaultProjectPhaseCaseName(Status)
		});

	}, [editState]);


	const handleSubmit = useCallback(async e => {

		e.preventDefault();

		setIsSubmitting(true);

		try {

			const updatedProject = await api({
				url: `/projects/${project.Id}`,
				method: "PUT",
				data: {
					Name: (editState.Name || null),
					SubheaderNotes: (editState.SubheaderNotes || null),
					/** Colour must be hex code without input's trailing `#` */
					Colour: (editState.Colour ? editState.Colour.replace("#", "") : null),
					SiteAddress: (
						Object.values((editState.SiteAddress || {})).some(v => v?.trim()) ?
							{
								Building: (editState.SiteAddress?.Building || null),
								Street: (editState.SiteAddress?.Street || null),
								City: (editState.SiteAddress?.City || null),
								Region: (editState.SiteAddress?.Region || null),
								Country: (editState.SiteAddress?.Country || null),
								Postcode: (editState.SiteAddress?.Postcode || null)
							} :
							null
					),
					Phase: (editState.Phase?.Name || editState.Phase),
					AssigneeType: (editState.AssigneeType?.Name || editState.AssigneeType),
					AssigneeNotes: editState.AssigneeNotes,
					AssignedStaffMember: (editState.AssignedStaffMember?.Id || null),
					AssignedCustomerContact: (editState.AssignedCustomerContact?.Id || null),
					Notes: (editState.Notes || null),
					/** Enum values must be submitted as scalars */
					Status: (editState.Status?.Name || editState.Status)
				}
			}).then(({data}) => data);

			setHasEdits(false);
			onUpdateProject(updatedProject);
			ToastStore.success("Project saved.");

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [editState, project, onUpdateProject]);


	const handleDeleted = useCallback(() => {

		if (onDeleteProject) {
			onDeleteProject(project);
		}
		else navigate(`/projects`);

	}, [project, navigate, onDeleteProject]);


	useEffect(() => {
		setEditState({...project});
	}, [project]);


	const RootComponent = (!embedded ? Segment : Container);


	return (
		<RootComponent
			className={className}>
			<Form
				onSubmit={handleSubmit}>
				<Flex gap={0.5}>
					<PaneHeader
						buttonDisabled={(!hasEdits || isSubmitting)}
						buttonIcon="check"
						buttonLabel="Save Changes"
						buttonType="submit"
						chip={
							<EnumChip
								enumClass={ProjectStatus}
								valueCase={project.Status} />
						}
						label={(project.Name || "(Unnamed Project)")}
						labelSub={
							!hideCustomerDetails &&
								(
									project.Customer ?
										<Link
											children={project.Customer.Name}
											to={`/customers/${project.Customer.Id}`} /> :
										"(Internal)"
								)
						} />
					<DetailsPanel
						defaultOpen={true}
						label="Project Details">
						<Form.Input
							disabled={isSubmitting}
							label="Project Name"
							placeholder="(Unnamed Project)"
							maxLength={255}
							name="Name"
							onChange={handleInputChange}
							value={(editState.Name || "")} />
						<Form.Input
							disabled={isSubmitting}
							label="Subheader Notes"
							placeholder="E.g. 8 x 20, 8 x 18, etc."
							maxLength={255}
							name="SubheaderNotes"
							onChange={handleInputChange}
							value={(editState.SubheaderNotes || "")} />
					</DetailsPanel>
					<DetailsPanel
						defaultOpen={true}
						label="Project Status">
						<ProjectStatusPicker
							disabled={isSubmitting}
							name="Status"
							onChange={handleStatusInputChange}
							required={true}
							value={(editState.Status?.Name || editState.Status)} />
						<ProjectPhasePicker
							disabled={isSubmitting}
							name="Phase"
							onChange={handleInputPropChange}
							projectStatus={(editState.Status?.Name || editState.Status)}
							required={true}
							value={(editState.Phase?.Name || editState.Phase)} />
						<ProjectAssigneeTypePicker
							disabled={isSubmitting}
							hiddenOptions={(!project.Customer ? ["Customer"] : undefined)}
							name="AssigneeType"
							label="Who's the Project With?"
							onChange={handleInputPropChange}
							required={true}
							value={(editState.AssigneeType?.Name || editState.AssigneeType)} />
						{
							((editState.AssigneeType?.Name || editState.AssigneeType) === "RedSky") &&
								<Form.Field
									disabled={isSubmitting}>
									<label
										children="Who's the Project With? — Staff Member" />
									<StaffMemberPicker
										clearable={true}
										disabled={isSubmitting}
										fluid={true}
										name="AssignedStaffMember"
										onChange={handleInputPropChange}
										projectAssigneeEnabled={true}
										value={editState.AssignedStaffMember} />
								</Form.Field>
						}
						{
							((editState.AssigneeType?.Name || editState.AssigneeType) === "Customer") &&
								<Form.Field
									disabled={isSubmitting}>
									<label
										children="Who's the Project With? — Customer Contact" />
									<CustomerContactPicker
										clearable={true}
										customerId={project.Customer.Id}
										disabled={isSubmitting}
										fluid={true}
										name="AssignedCustomerContact"
										onChange={handleInputPropChange}
										value={editState.AssignedCustomerContact} />
								</Form.Field>
						}
						{
							((editState.AssigneeType?.Name || editState.AssigneeType) === "Other") &&
								<Form.Input
									disabled={isSubmitting}
									label="Who's the Project With? — Notes"
									placeholder="Who's the Project With? — Notes"
									maxLength={255}
									name="AssigneeNotes"
									onChange={handleInputChange}
									value={(editState.AssigneeNotes || "")} />
						}
					</DetailsPanel>
					<DetailsPanel
						label="Site Address">
						<AddressFormInputs
							disabled={isSubmitting}
							name="SiteAddress"
							onChange={handleInputPropChange}
							values={editState.SiteAddress} />
					</DetailsPanel>
					<DetailsPanel
						defaultOpen={true}
						label="Notes">
						<Form.TextArea
							disabled={isSubmitting}
							name="Notes"
							placeholder="Notes"
							maxLength={65535}
							onChange={handleInputChange}
							value={(editState.Notes || "")} />
					</DetailsPanel>
					<Button
						basic={true}
						color="red"
						content="Delete Project"
						icon="trash alternate"
						disabled={isSubmitting}
						onClick={deletionDialogOpen.setTrue}
						size="tiny"
						type="button" />
				</Flex>
			</Form>
			{
				deletionDialogOpen &&
					<ProjectDeletionDialog
						projectId={project?.Id}
						open={deletionDialogOpen.value}
						onClose={deletionDialogOpen.setFalse}
						onDeleted={handleDeleted} />
			}
		</RootComponent>
	);

});

export default ProjectDetailsPanel;
