import EmptyStateLabel from "./EmptyStateLabel.js";
import scss from "./Loadable.module.scss";
import {memo} from "react";
import {Button, Loader} from "semantic-ui-react";

const Loadable = memo(({children, loader, loading, loaderClassName, empty, emptyMessage, error, errorMessage, onErrorRetry}) => {

	if (loading) {
		return (
			loader ||
				<Loader
					active={true}
					className={loaderClassName}
					inline="centered" />
		);
	}
	else if (error) {
		return (
			<div className={scss.errorContainer}>
				<p className={scss.error}>
					{(errorMessage || "Error.")}
				</p>
				{
					onErrorRetry &&
						<Button
							basic={true}
							content="Retry"
							icon="redo"
							onClick={onErrorRetry}
							size="small" />
				}
			</div>
		);
	}
	else if (empty) {
		return (
			<div className={scss.errorContainer}>
				<EmptyStateLabel label={(emptyMessage || "Nothing to show.")} />
			</div>
		);
	}
	else return (children || null);

});

export default Loadable;
